// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'user',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'order_detail',
    label: '',
    Object: 'value',
    width: '300'
  }, {
    prop: 'contact_info',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'status',
    back: true,
    label: '',
    Object: 'value',
    width: '130'
  }]
}
